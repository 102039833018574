<template>
    <div class="mt-3 col-md-12" id="filters">
      <form id="filterForm" @submit.prevent="submit">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="lastName">Customer Last Name</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="lastName"
                placeholder="Last Name"
                v-model="applicationFilter.lastName"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="firstName">Customer First Name</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="firstName"
                placeholder="First Name"
                v-model="applicationFilter.firstName"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label for="phone">Customer Phone Number</label>
            <input
                type="tel"
                class="form-control form-control-sm"
                id="phone"
                placeholder="Phone Number"
                v-model="applicationFilter.phone"
                @keyup="changePhone('phone')">
          </div>
          <div class="col">
            <label for="emailAddress">Customer Email</label>
            <input
                type="email"
                class="form-control form-control-sm"
                id="emailAddress"
                placeholder="Email Address"
                v-model="applicationFilter.email">
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="startDate">Created Start Date</label>
              <input type="date" class="form-control form-control-sm" id="startDate" v-model="applicationFilter.startDate">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="endDate">Created End Date</label>
              <input type="date" class="form-control form-control-sm" id="endDate" v-model="applicationFilter.endDate">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-3">
            <div class="form-group">
              <label for="statusSelections">Application Status</label>
              <select multiple class="form-control form-control-sm" id="statusSelections" v-model="applicationFilter.statusValues">
                <option v-bind:class="statusStyle(status.active)"
                  v-for="(status) in statusOptions"
                  :key="status.id"
                >{{status.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-3 mr-auto">
            <div class="form-group">
              <label for="userSelections">User List</label>
              <select multiple class="form-control form-control-sm" id="userSelections" v-model="applicationFilter.users" :disabled="disableUserSelection">
                <option
                  v-for="(user) in userList"
                  :key="user.id"
                  :value="{ id: user.id }"
                >{{user.firstName}} {{user.lastName}} ({{user.username}})
                </option>
              </select>
            </div>
          </div>
          <div class="col-3 mr-auto">
            <div class="form-group">
              <label for="userSelections">Lead Source</label>
              <select multiple class="form-control form-control-sm" id="leadSourceSelections" v-model="applicationFilter.leadSources">
                <option
                    v-for="(leadSource) in leadSources"
                    :key="leadSource.id"
                >{{leadSource.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-3 mr-auto">
            <div class="form-group">
              <label for="userSelections">State</label>
              <select multiple class="form-control form-control-sm" id="stateSelections" v-model="applicationFilter.states">
                <option
                    v-for="(state) in states"
                    :key="state.id"
                    :value="{ id: state.id }"
                >({{state.shortName}}) {{state.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-3">
            <div class="form-group">
              <label for="statusSelections">Second Application Status</label>
              <select multiple class="form-control form-control-sm" id="secondStatusSelections" v-model="applicationFilter.secondStatusValues">
                <option
                        v-for="(secondStatus) in secondStatusOptions"
                        :key="secondStatus.id"
                >{{secondStatus.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-row">
            <div class="col-md-12 text-center">
              <form>
                <button @click.prevent="submit" class="btn btn-secondary mt-2 m-3">Refresh</button>
                <button @click.prevent="clear" class="btn btn-secondary mt-2 m-3">Reset Filters</button>
              </form>
            </div>
          </div>
        </div>

      </form>
    </div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import Store from '@/store'

export default {
  name: 'searchFilters',
  data: function () {
    return {
      applicationFilter: {
        firstName: null,
        lastName: null,
        startDate: null,
        endDate: null,
        phone: null,
        email: null,
        statusValues: [],
        secondStatusValues: [],
        leadSources: [],
        states: [],
        stateIds: [],
        userIds: []
      }
    }
  },
  computed: {
    disableUserSelection: function() {
      return !Store.state.userInfo.admin;
    }
  },
  created: async function () {
    if (Store.state.applicationFilter != null) {

      this.applicationFilter.firstName = Store.state.applicationFilter.firstName;
      this.applicationFilter.lastName = Store.state.applicationFilter.lastName;
      this.applicationFilter.phone = Store.state.applicationFilter.phone;
      this.applicationFilter.email = Store.state.applicationFilter.email;
      this.applicationFilter.startDate = moment(Store.state.applicationFilter.startDate).format("YYYY-MM-DD")
      this.applicationFilter.endDate = moment(Store.state.applicationFilter.endDate).format("YYYY-MM-DD")
      this.applicationFilter.leadSources = Store.state.applicationFilter.leadSources;
      this.applicationFilter.states = Store.state.applicationFilter.states;
      this.applicationFilter.stateIds = Store.state.applicationFilter.stateIds;
      this.applicationFilter.statusValues = Store.state.applicationFilter.statusValues;
      this.applicationFilter.secondStatusValues = Store.state.applicationFilter.secondStatusValues;
      this.applicationFilter.userIds = Store.state.applicationFilter.userIds;
      this.applicationFilter.users = Store.state.applicationFilter.users;

      let applicationFilter = lodash.cloneDeep(this.applicationFilter);
      applicationFilter.startDate = moment(this.applicationFilter.startDate).format();
      applicationFilter.endDate = moment(this.applicationFilter.endDate).format();

      this.changePhone();

      this.$emit("refresh", applicationFilter);
    }
    else {
      this.clear();
    }
  },
  methods: {
    statusStyle: function(active) {
      if (active) {
        return '';
      }
      else {
        return 'text-secondary';
      }
    },
    clear: function() {
      this.applicationFilter.firstName = null;
      this.applicationFilter.lastName = null;
      this.applicationFilter.phone = null;
      this.applicationFilter.email = null;
      this.applicationFilter.startDate = moment().subtract(1, 'year').format("YYYY-MM-DD");
      this.applicationFilter.endDate =  moment().add(1, 'day').format("YYYY-MM-DD");
      this.applicationFilter.statusValues = [];
      this.applicationFilter.secondStatusValues = [];
      this.applicationFilter.userIds = [];
      this.applicationFilter.users = [];
      this.applicationFilter.leadSources = [];
      this.applicationFilter.states = [];
      this.applicationFilter.stateIds = [];

      if (!Store.state.userInfo.admin) {
        this.applicationFilter.users[0] = { id: Store.state.userInfo.id };
      }

      Store.state.applicationFilter = null;

      this.submit();
    },
    changePhone: function() {
      if (this.applicationFilter.phone) {
        let value = this.applicationFilter.phone;

        // Strip all characters from the input except digits and trim to ten characters
        value = value.replace(/\D/g,'');
        value = value.substring(0,10);

        // Format based on the length of the string
        let size = value.length;

        if (size === 0) {
          // NOP
        } else if (size < 4) {
          value = '(' + value;
        } else if (size < 7) {
          value = '(' + value.substring(0,3) + ') ' + value.substring(3,6);
        } else {
          value = '(' + value.substring(0,3) + ') ' + value.substring(3,6) + '-' + value.substring(6,10);
        }

        this.applicationFilter.phone = value;
      }
    },
    submit: async function () {
      //
      // Build our filter from our filter form values
      //
      var applicationFilter = {
        firstName: this.applicationFilter.firstName,
        lastName: this.applicationFilter.lastName,
        startDate: moment(this.applicationFilter.startDate + ' ' + '00:00:00').format(),
        endDate: moment(this.applicationFilter.endDate + ' ' + '23:59:59').format(),
        statusValues: this.applicationFilter.statusValues,
        secondStatusValues: this.applicationFilter.secondStatusValues,
        leadSources: this.applicationFilter.leadSources,
        states: this.applicationFilter.states,
        stateIds: [],
        users: this.applicationFilter.users,
        userIds: [],
        phone: (this.applicationFilter.phone)
            ? this.applicationFilter.phone
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll(' ', '')
                .replaceAll('-', '')
            : null,
        email: this.applicationFilter.email
      };

      //
      // The service wants an array of ids, not an array of objects, so convert that here
      //
      for (let idx = 0; idx < this.applicationFilter.users.length; idx++) {
        applicationFilter.userIds[idx] = this.applicationFilter.users[idx].id;
      }

      for (let idx = 0; idx < this.applicationFilter.states.length; idx++) {
        applicationFilter.stateIds[idx] = this.applicationFilter.states[idx].id;
      }

      //
      // Save filters to storage
      //
      Store.state.applicationFilter = {};
      Store.state.applicationFilter.firstName = lodash.cloneDeep(applicationFilter.firstName);
      Store.state.applicationFilter.lastName = lodash.cloneDeep(applicationFilter.lastName);
      Store.state.applicationFilter.phone = lodash.cloneDeep(applicationFilter.phone);
      Store.state.applicationFilter.email = lodash.cloneDeep(applicationFilter.email);
      Store.state.applicationFilter.startDate = lodash.cloneDeep(applicationFilter.startDate);
      Store.state.applicationFilter.endDate = lodash.cloneDeep(applicationFilter.endDate);
      Store.state.applicationFilter.statusValues = lodash.cloneDeep(applicationFilter.statusValues);
      Store.state.applicationFilter.secondStatusValues = lodash.cloneDeep(applicationFilter.secondStatusValues);
      Store.state.applicationFilter.leadSources = lodash.cloneDeep(applicationFilter.leadSources);
      Store.state.applicationFilter.states = lodash.cloneDeep(applicationFilter.states);
      Store.state.applicationFilter.stateIds = lodash.cloneDeep(applicationFilter.stateIds);
      Store.state.applicationFilter.userIds = lodash.cloneDeep(applicationFilter.userIds);
      Store.state.applicationFilter.users = lodash.cloneDeep(applicationFilter.users);

      //
      // Send the message to our parent to refresh the list of applications
      //
      this.$emit("refresh", applicationFilter);
    },
    formatDateOutput: async function(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  },
  props: ['statusOptions', 'secondStatusOptions', 'leadSources', 'userList', 'states'],
}
</script>
