<template>

  <div class="container">

    <div id="ApplicationModal">
      <ApplicationModal v-if="showApplicationModal" :passedData="passedData" @create="showApplication" @cancel="cancelAddApplication" />
    </div>

    <div id="confirmApplicationArchiveModal">
      <ConfirmModal v-if="showConfirmApplicationArchiveModal" @confirm="archiveApplication()" @cancel="showConfirmApplicationArchiveModal = false">
        <!-- Set these slot data elements to customize the modal -->
        <h3 slot="header">CONFIRM ARCHIVE</h3>
        <p slot="body">
          Are you sure you want to ARCHIVE the application record for {{applicationData.application.customerName}}?
        </p>
      </ConfirmModal>
    </div>

    <div id="confirmApplicationDeleteModal">
      <ConfirmModal v-if="showConfirmApplicationDeleteModal" @confirm="deleteApplication()" @cancel="showConfirmApplicationDeleteModal = false">
        <h3 slot="header">CONFIRM DELETE</h3>
        <p slot="body">
          Are you sure you want to DELETE the application record for {{applicationData.application.customerName}}?
        </p>
      </ConfirmModal>
    </div>

    <h5>Customer Applications</h5>

    <SearchFilters @refresh="refresh" v-if="showFilters"
                   :lead-sources="this.leadSources"
                   :states="this.state"
                   :status-options="this.statusOptions"
                   :second-status-options="this.secondStatusOptions"
                   :user-list="this.userList"
    />

    <div class="m-3">
      <a
        href="#"
        class="custom-fg-dark"
        @click="showFilters = !showFilters"
      >{{(showFilters) ? "hide filters" : "show filters"}}</a>
    </div>

    <div class="card" id="results">

      <div class="card-header container">
        <div class="row">
          <div class="col-sm">
            <h5 class="mt-2">Application Search Results</h5>
          </div>
        </div>
      </div>


      <div class="container mt-2">
        <div class="row">
          <div class="col-auto" v-if="returnedRowCount > 0">
            <input id="searchResults" placeholder="Filter Results" type="text" class="form-control" aria-label="Filter Results" v-model="searchTerm">
          </div>
          <div class="col-auto custom-cursor-pointer mt-2" @click="showApplicationCreate">
            <!-- <font-awesome-icon icon="plus-circle" class="custom-fg-dark mr-2" /> -->
            <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
            <span class="custom-fg-dark">Add Application</span>
          </div>
          <div class="col-auto custom-cursor-pointer">
            <ul class="pagination col justify-content-end">
              <li class="page-item disabled">
                <button class="btn btn-secondary" :disabled="isFirstPage || isPaginationBlocked" v-on:click="goToPage(1)">&lsaquo;&lsaquo; First </button>
              </li>
              <li class="page-item disabled">
                <button class="btn btn-secondary" :disabled="isFirstPage || isPaginationBlocked" v-on:click="goToPage((currentPage - 1))">&lsaquo; Prev</button>
              </li>
              <li v-for="(pageNumber) in pageNumbers" v-bind:key="pageNumber">
                <button v-bind:class="currentButtonClass(pageNumber)" class="btn" :disabled="isPaginationBlocked" v-on:click="goToPage(pageNumber)">{{pageNumber}}</button>
              </li>
              <li class="page-item">
                <button class="btn btn-secondary" :disabled="isLastPage || isPaginationBlocked" v-on:click="goToPage((currentPage + 1))">Next &rsaquo;</button>
              </li>
              <li class="page-item">
                <button class="btn btn-secondary" :disabled="isLastPage || isPaginationBlocked" v-on:click="goToPage(totalPages)">Last &rsaquo;&rsaquo;</button>
              </li>
            </ul>
          </div>
          <div class="mr-auto mt-2" v-if="returnedRowCount > 0" v-cloak>
            <p class="custom-fg-dark">{{totalElements}} total ({{size}} per page)</p>
          </div>
          <div class="col-auto mr-auto" v-cloak v-if="returnedRowCount <= 0">
            <p class="custom-fg-dark">No rows found - please adjust your filters</p>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med custom-sticky-header sticky-top">
          <tr>
            <th scope="col">Consultant</th>
            <th scope="col">Status</th>
            <th scope="col">Complete</th>
            <th scope="col">Source</th>
            <th scope="col">State</th>
            <th scope="col">Purpose</th>
            <th scope="col">Amount</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Created</th>
            <th scope="col">Updates</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="small-font-table" v-cloak>
          <tr
            v-for="(application, index) in filteredResult"
            v-on:dblclick="routeToEdit(application)"
            :key="application.id"
            :class="{'table-warning': newStatus(application)}"
          >
            <td v-if="isAdmin || isUserWritable(application)" class="col-md-2 mr-0">
              <select class="custom-select-dark" id="userId" @change="assignLoanOfficer(application.id, $event)">
                <option
                    v-for="(user) in userList"
                    :key="user.id"
                    :selected="application.userName === user.username"
                    :value="user.id">{{user.firstName}} {{user.lastName}}
                </option>
              </select>
            </td>
            <td v-else class="custom-fg-dark">{{application.fullName}}</td>

            <td class="col-md-2">
                <p class="mb-0 custom-label">First status:</p>
              <select v-if="isAdmin" class="mb-1 select-style" :class="applicationStatusClass(application)" id="status" @change="setStatus(application.id, $event)">
                <option selected :value="application.status">{{application.status}}</option>
                <option
                    v-for="(status) in statusOptions.filter(s => s.active).filter(s => s.value !== application.status)"
                    :key="status.id"
                    :value="status.value">{{status.value}}</option>
              </select>
              <p v-else class="mb-1" :class="applicationStatusClass(application)" >{{application.status ? application.status : 'Not Selected'}}</p>

              <p class="mb-0 custom-label">Second status:</p>
              <select class="select-style" id="secondStatus" @change="setSecondStatus(application.id, $event)">
                <option selected :value="application.secondStatus">{{application.secondStatus}}</option>
                <option
                    v-for="(secondStatus) in secondStatusOptions.filter(s => s.active).filter(s => s.value !== application.secondStatus)"
                    :key="secondStatus.id"
                    :value="secondStatus.value">{{secondStatus.value}}</option>
              </select>
            </td>

            <td class="custom-fg-dark">
              <input class="big-checkbox" type="checkbox" v-model="application.simpleNexusCompleted" disabled>
            </td>

            <td class="custom-fg-dark">{{application.leadSource}}</td>
            <td class="custom-fg-dark">{{application.propertyState}}</td>
            <td class="custom-fg-dark">{{application.loanPurpose}}</td>
            <td class="custom-fg-dark text-right">{{formatCurrency(application.loanAmount)}}</td>
            <td class="custom-fg-dark">{{application.customerName}}</td>
            <td class="custom-fg-dark custom-date-td">{{formatDate(application.created)}}</td>
            <td class="custom-fg-dark col-md-4 custom-status-td">
              <p class="mb-0 custom-label">First status:</p>
              {{application.firstStatusChanged ? formatDate(application.firstStatusChanged) : 'No data'}}
              <p class="mb-0 custom-label">Second status:</p>
              {{application.secondStatusChanged ? formatDate(application.secondStatusChanged) : 'No data'}}
            </td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-secondary btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <router-link :to="{name: 'ApplicationsEdit', params: {applicationId: application.id}}" class="dropdown-item">Edit</router-link>
                  <router-link :to="{name: 'Quotes', params: {applicationId: application.id}}" class="dropdown-item">Quote</router-link>
                  <div class="dropdown-divider" v-if="isAdmin"></div>
                  <a class="dropdown-item" href="#" v-if="isAdmin" @click="confirmArchiveApplication(index)">Archive</a>
                  <a class="dropdown-item" href="#" v-if="isAdmin" @click="confirmDeleteApplication(index)">Delete</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>
import moment from 'moment'
import 'moment-timezone';
import numeral from 'numeral'

import Store from '@/store'

import Application from '@/services/ApplicationService'

import ApplicationModal from '@/components/modals/ApplicationModal'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SearchFilters from '@/components/applications/SearchFilters'
import PickDetail from "@/services/PickDetailService";
import Users from "@/services/UserService";
import State from "@/services/StateService";

export default {
  name: 'applications',
  components: {
    ApplicationModal,
    ConfirmModal,
    SearchFilters
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  created: async function () {
    this.statusOptions = await PickDetail.getPickListDetail('applicationstatus');
    this.secondStatusOptions = await PickDetail.getPickListDetail('secondapplicationstatus')
    this.leadSources = await PickDetail.getPickListDetail('leadsource');
    this.state = await State.getStates('state');
    this.userList = await Users.getUserList();
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.applications.filter(result => {
        var idStr = result.id.toString();

        return (
          (result.customerName && result.customerName.toLowerCase().match(this.searchTerm.toLowerCase())) ||
          result.status && result.status.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          idStr.match(this.searchTerm)
        );
      });
    },
    pageNumbers: function () {
      let fullPageNumbers = Array.from(Array.from({ length: this.totalPages }, (_, i) => i + 1))

      return fullPageNumbers.filter((_, i) => Math.abs(this.currentPage - i) < 3);
    },
    returnedRowCount: function () {
      return this.applications.length;
    },
  },
  data: function () {
    return {
      applications: [],
      applicationData: {},
      passedData: {},
      searchTerm: "",
      showFilters: true,
      showApplicationModal: false,
      showConfirmApplicationArchiveModal: false,
      showConfirmApplicationDeleteModal: false,
      statusOptions: [],
      secondStatusOptions: [],
      userList: [],
      leadSources: [],
      state: [],
      currentPage: 1,
      size: 50,
      applicationFilter: null,
      focusedId: -1,
      totalPages: 0,
      totalElements: 0,
      isFirstPage: true,
      isLastPage: false,
      isPaginationBlocked: false
    }
  },
  methods: {
    archiveApplication: async function() {
      await Application.archiveApplication(this.applicationData.application.id);

      this.applications.splice(this.applicationData.applicationIdx, 1);

      this.showConfirmApplicationArchiveModal = false;
    },
    currentButtonClass(pageNum) {
      if (pageNum === this.currentPage) {
        return "btn-primary";
      }

      return "btn-secondary";
    },
    applicationStatusClass(application) {

        if (application.status === "New Applicant Lead" || application.status === "New Lead Organic") {
          return "custom-select-red";
        }
        if (application.status === "New Lead") {
          return "custom-select-blue";
        }
        if (application.status === "New Rate Alert") {
          return "custom-select-purple";
        }
        if (application.status === "Verse Live Transfer" || application.status === "Verse Appt") {
          return "custom-select-green";
        }
        if (application.status === "Duplicate") {
          return "custom-select-yellow";
        }
        if (application.status === "Verse Working") {
          return "custom-select-orange";
        }

        return "custom-select-white";

    },
    cancelAddApplication: function() {
      this.showApplicationModal = false;
    },
    confirmArchiveApplication: function (applicationIdx) {
      this.applicationData = {
        application: this.applications[applicationIdx],
        applicationIdx: applicationIdx
      }
      this.showConfirmApplicationArchiveModal = true;
    },
    confirmDeleteApplication: function (applicationIdx) {
      this.applicationData = {
        application: this.applications[applicationIdx],
        applicationIdx: applicationIdx
      }
      this.showConfirmApplicationDeleteModal = true;
    },
    showApplication: function(applicationId) {
      console.log("showApplication: " + applicationId)
      this.showApplicationModal = false;
      this.$router.push({name: 'ApplicationsEdit', params: {applicationId: applicationId}});
    },
    deleteApplication: async function() {
      await Application.deleteApplication(this.applicationData.application.id);

      this.applications.splice(this.applicationData.applicationIdx, 1);

      this.showConfirmApplicationDeleteModal = false;
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatDate: function (date) {
      if (!date) {
        return ''
      }

      // we need to use it because we get timestamp from backend
      let createdDate = moment(date)

      let hoursDiff = moment.duration(moment().diff(createdDate)).asHours();

      if (hoursDiff > 24) {
        return createdDate.format('MM/DD/YY');
      } else {
        return createdDate.fromNow();
      }
    },
    isUserWritable(application) {
      return (application.status && application.status === 'Verse Live Transfer') ||
          (application.leadSource && application.leadSource === 'FreeRateUpdate')
    },
    newStatus: function (application) {
      var aged = moment().subtract(30, 'days').isAfter(application.modified);

      return application.status == 'New' && aged;
    },
    refresh: async function(applicationFilter) {
      //
      // How is this called when the page loads? It's called by the
      // SearchFilters component issuing an emit.
      //
      this.applicationFilter = applicationFilter;
      this.currentPage = 1;

      this.isPaginationBlocked = true;

      let response = await Application.findApplications(applicationFilter, this.currentPage, this.size);

      this.isPaginationBlocked = false;

      this.applications = response.content;
      this.isFirstPage = response.first;
      this.isLastPage = response.last;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;
    },
    routeToEdit: function(application) {
      this.$router.push({name: 'ApplicationsEdit', params: {applicationId: application.id}});
    },
    showApplicationCreate: function() {
      this.passedData = {
        dataWasPassed: false,
      }
      this.showApplicationModal = true;
    },
    assignLoanOfficer: async function(applicationId, event) {
      await Application.assignLoanOfficer(applicationId, event.target.value);
    },
    setStatus: async function(applicationId, event) {
      await Application.setStatus(applicationId, event.target.value);

      this.applications = await this.refresh(this.applicationFilter)
    },
    setSecondStatus: async function(applicationId, event) {
      await Application.setSecondStatus(applicationId, event.target.value);

      this.applications = await this.refresh(this.applicationFilter)
    },
    goToPage: async function (page) {
      this.isPaginationBlocked = true;

      let response = await Application.findApplications(this.applicationFilter, page, this.size);

      this.isPaginationBlocked = false;

      this.applications = response.content;

      this.currentPage = page;
      this.applications = response.content;
      this.isFirstPage = response.first;
      this.isLastPage = response.last;
      this.totalPages = response.totalPages;
      this.totalElements = response.totalElements;
    }
  }
}
</script>
